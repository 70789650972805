import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoButtonModule,
  XpoCardModule,
  XpoConfirmDialogModule,
  XpoDialogModule,
  XpoEmptyStateModule,
  XpoFeedbackModule,
  XpoSelectModule,
  XpoShellModule,
  XpoSnackBarModule,
  XpoStatusIndicatorModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoChipsModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoSsiModule } from '@xpo-ltl/ngx-ltl-core';
import { DecimalNumberEditor } from './cell-renderer/decimal-numeric-editor.component';
import { XpoLtlPipeModule } from './pipes';

const AngularMaterialModules = [
  MatDialogModule,
  MatInputModule,
  MatIconModule,
  MatListModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatFormFieldModule,
  MatInputModule,
  MatDividerModule,
  MatAutocompleteModule,
];

const XpoModules = [
  XpoSelectModule,
  XpoChipsModule,
  XpoButtonModule,
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoCardModule,
  XpoDialogModule,
  XpoShellModule,
  XpoFeedbackModule,
  XpoSnackBarModule,
  XpoConfirmDialogModule,
  XpoStatusIndicatorModule,
  XpoSsiModule,
  XpoEmptyStateModule,
];
@NgModule({
  declarations: [
    // Components
    DecimalNumberEditor,
    // Pipes/Directives
  ],
  imports: [
    // Pipes/Directives
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...AngularMaterialModules,
    ...XpoModules,
    XpoLtlPipeModule,
  ],
  exports: [
    // Modules
    FormsModule,
    ReactiveFormsModule,
    ...AngularMaterialModules,
    ...XpoModules,
    XpoLtlPipeModule,
    // Components
    DecimalNumberEditor,
    // Pipes/Directives
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
  providers: [],
})
export class SharedModule {}
