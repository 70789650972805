import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {XpoAuthenticationGuard} from '@xpo/ngx-auth';
import {AppRoutes} from './shared/enums/app-routes.enum';

const routes: Routes = [
  {
    path: AppRoutes.PRO_SEARCH,
    loadChildren: () => import('./mover-pro-search/mover-pro-search.module').then((m) => m.MoverProSearchModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.TRACKING,
    loadChildren: () => import('./tracking/tracking.module').then((m) => m.TrackingModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: '',
    redirectTo: AppRoutes.PRO_SEARCH,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
