import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { SdkShipmentModule } from '@xpo-ltl-2.0/sdk-shipment';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiModule } from '@xpo-ltl/data-api';
import { XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XpoFooterModule, XpoShellModule } from '@xpo-ltl/ngx-ltl-core';
import { SdkProNumberReengineeringModule } from '@xpo-ltl/sdk-pronumberreengineering';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: environment.authAppRootUri,
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    BrowserModule,
    ConfigManagerModule,
    DataApiModule,
    SdkProNumberReengineeringModule,
    SdkShipmentModule,
    SharedModule,
    XpoShellModule,
    XpoFooterModule,
    XpoLtlAuthUiModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: 'plt' }),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    LocationApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
