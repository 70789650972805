export enum ConfigManagerProperties {
  buildVersion = 'buildVersion',
  production = 'production',
  apiUrl = 'apiUrl',
  scopeOptions = 'scopeOptions',
  secretToken = 'secretToken',
  appName = 'appName',
  logProjectName = 'logProjectName',
  logAppName = 'logAppName',
  region = 'region',
  loggedInUserRoot = 'loggedInUserRoot',
  userContextPath = 'userContextPath',
  appMetadataEndpoint = 'appMetadataEndpoint',
  bolApiEndpoint = 'bolApiEndpoint',
  customerLocationApiEndpoint = 'customerLocationApiEndpoint',
  elasticSearchApiEndpoint = 'elasticSearchApiEndpoint',
  freightFlowApiEndpoint = 'freightFlowApiEndpoint',
  infrastructureApiEndpoint = 'infrastructureApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  locationApiEndpoint = 'locationApiEndpoint',
  locationV2ApiEndpoint = 'locationV2ApiEndpoint',
  loggingApiEndpoint = 'loggingApiEndpoint',
  membershipApiEndpoint = 'membershipApiEndpoint',
  pricingApiEndpoint = 'pricingApiEndpoint',
  shipmentApiEndpoint = 'shipmentApiEndpoint',
  userpreferenceApiEndpoint = 'userpreferenceApiEndpoint',
  errorToastDuration = 'errorToastDuration',
  imageCorpCode = 'imageCorpCode',
  successToastDuration = 'successToastDuration',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  serviceCenterInformationLink = 'serviceCenterInformationLink',
  rulesetViewerLink = 'rulesetViewerLink',
  baseLtlPathDev = 'baseLtlPathDev',
  baseLtlLegacyPathDev = 'baseLtlLegacyPathDev',
  baseLtlPathProd = 'baseLtlPathProd',
  baseLtlLegacyPathProd = 'baseLtlLegacyPathProd',
  dockOperationsApiEndpoint = 'dockOperationsApiEndpoint',
}
