<xpo-shell [drawerClosedByDefault]="true">
  <xpo-header-app-name>Mover</xpo-header-app-name>
  <xpo-header-sub-app-name>PRO</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <button routerLink="pro-search" routerLinkActive="xpo-selected">MOVER</button>
    <button routerLink="tracking" routerLinkActive="xpo-selected">TRACKING</button>
  </xpo-header-navigation>
  <xpo-header-actions>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <div class="content">
    <router-outlet></router-outlet>
    <xpo-footer xpoFooterSticky [version]="build"></xpo-footer>
  </div>
</xpo-shell>
<xpo-app-notification></xpo-app-notification>
