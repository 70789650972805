export enum AppRoutes {
  PRO_SEARCH = 'pro-search',
  TRACKING = 'tracking',
  SHP_DETAILS = 'shp-details',
  TRAILER_MANIFEST = 'trailer-manifest',
  DOCK_WORKER = 'dock-worker',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  PRO_NUMBERS = 'pro-nbrs',
  DETAIL = 'detail',
}
