import { AfterViewChecked, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

export enum ValidationKeys {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  TAB = 'Tab',
}

@Component({
  selector: 'app-editor-cell',
  template: `
    <input
      type="number"
      (focusout)="onFocusOut($event)"
      (focusin)="onFocusIn()"
      [maxlength]="maxLength"
      class="xpo-AgGrid-cellEditor--numeric-input"
      #input
      (keydown)="onKeyDown($event)"
      [(ngModel)]="value"
    />
  `,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor--numeric',
  },
  styleUrls: ['./decimal-numeric-editor.component.scss'],
})
export class DecimalNumberEditor implements ICellEditorAngularComp, AfterViewChecked {
  @ViewChild('input', { static: true }) input;
  params: any;
  value: number;
  maxLength: number;

  ngAfterViewChecked(): void {
    this.input.nativeElement.focus();
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = parseFloat(this.params.value);
    this.maxLength = this.params.maxLength;
  }

  getValue() {
    return this.value;
  }

  onKeyDown(event): void {
    if (!this.isDeleting(event.key) && !this.isKeyPressedNumeric(event.key) && !this.isTabKeyPressed(event.key)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  isTabKeyPressed(key: any): boolean {
    return key === ValidationKeys.TAB;
  }

  isCancelAfterEnd(): boolean {
    return this.value && this.value.toString().length > this.maxLength;
  }

  onFocusIn(): void {
    this.input.nativeElement.select();
  }

  onFocusOut(e): void {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }

  private isKeyPressedNumeric(value): boolean {
    return !!/\d|.|,/.test(value);
  }

  private isDeleting(key): boolean {
    return key === ValidationKeys.BACKSPACE || key === ValidationKeys.DELETE;
  }
}
