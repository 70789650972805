import { Injectable } from '@angular/core';
import { XpoLtlConditioningService } from '../conditioning-service/conditioning.service';
import { trim as _trim, toString as _toString } from 'lodash';
import { XpoLtlValidationRegexPatterns } from '../../enums/validation-regex-patterns.enum';

@Injectable({ providedIn: 'root' })
export class XpoLtlFormatValidationService {
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly numberTrailerPattern = XpoLtlValidationRegexPatterns.numberTrailerPattern;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly letterTrailerPattern = XpoLtlValidationRegexPatterns.letterTrailerPattern;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly numberCheck = XpoLtlValidationRegexPatterns.numberCheck;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly doorPattern = XpoLtlValidationRegexPatterns.doorPattern;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly nineDigitPro = XpoLtlValidationRegexPatterns.nineDigitPro;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly tenDigitPro = XpoLtlValidationRegexPatterns.tenDigitPro;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly elevenDigitPro = XpoLtlValidationRegexPatterns.elevenDigitPro;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly emailValidationPattern = XpoLtlValidationRegexPatterns.emailValidationPattern;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly phoneNumberPattern = XpoLtlValidationRegexPatterns.phoneNumberPattern;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly multiEmailsPattern = XpoLtlValidationRegexPatterns.multiEmailsPattern;

  /**
   * This expression matches three different formats of postal codes: 5 digit US ZIP code,
   * 5 digit US ZIP code + 4, and 6 digit alphanumeric Canadian Postal Code. The first one
   * must be 5 numeric digits. The ZIP+4 must be 5 numeric digits, a hyphen, and then 4
   * numeric digits. The Canadian postal code must be of the form ANA NAN where A is any
   * uppercase alphabetic character and N is a numeric digit from 0 to 9.
   */
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly usZipCode = XpoLtlValidationRegexPatterns.usZipCode;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly caPostalCode = XpoLtlValidationRegexPatterns.caPostalCode;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly mxPostalCode = XpoLtlValidationRegexPatterns.mxPostalCode;

  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly madCodeValidationPattern = XpoLtlValidationRegexPatterns.madCodeValidationPattern;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly billToMadCodeValidationPattern = XpoLtlValidationRegexPatterns.billToMadCodeValidationPattern;
  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly sicValidationPattern = XpoLtlValidationRegexPatterns.sicValidationPattern;

  /**
   * @deprecated Use XpoLtlValidationRegexPatterns instead
   */
  readonly nmfcValidationPattern = XpoLtlValidationRegexPatterns.nmfcValidationPattern;

  private _emailSplitPattern = '[\\s,;]';

  constructor(private conditioningService: XpoLtlConditioningService) {}

  isValidEmail(inputValue: string): boolean {
    if (!inputValue) {
      return false;
    }
    const regExp = new RegExp(XpoLtlValidationRegexPatterns.emailValidationPattern, 'i');
    return regExp.test(inputValue);
  }

  isValidEmails(inputValue: string | Array<string> | undefined): boolean {
    if (Array.isArray(inputValue)) {
      return inputValue.every((value) => this.isValidEmail(value));
    }
    return this.isValidEmails(this.splitEmailGroup(inputValue));
  }

  private splitEmailGroup(inputValue: string |undefined): Array<string> | undefined {
    if (!inputValue) {
      return undefined;
    }
    return inputValue
      .split(new RegExp(this._emailSplitPattern, 'g'))
      .map((item) => item.trim())
      .filter((item) => !!item);
  }

  isValidProNumber(inputValue: string | null | undefined) {
    return this.isValidProNumberCheckDigit(inputValue);
  }
  isValidCallNumber(inputValue: string) {
    return new RegExp(XpoLtlValidationRegexPatterns.callNumber).test(inputValue);
  }
  isValidClaimNumber(inputValue: string) {
    return new RegExp(XpoLtlValidationRegexPatterns.claimNumber, 'i').test(inputValue);
  }
  isValidDisputeNumber(inputValue: string) {
    return new RegExp(XpoLtlValidationRegexPatterns.disputePattern, 'i').test(inputValue);
  }
  isValidRouteName(inputValue: string) {
    return new RegExp(XpoLtlValidationRegexPatterns.routeName).test(inputValue);
  }

  isValidDoorNumber(inputValue: string) {
    const regExp = new RegExp(XpoLtlValidationRegexPatterns.doorPattern);
    return regExp.test(inputValue);
  }
  isValidMexicoPostalCode(inputValue: string | null | undefined) {
    return new RegExp(XpoLtlValidationRegexPatterns.mxPostalCode).test(inputValue as string);
  }
  isValidUsZipCode(inputValue: string | null | undefined) {
    return new RegExp(XpoLtlValidationRegexPatterns.usZipCode).test(inputValue as string);
  }
  isValidCanadianPostalCode(inputValue: string | null | undefined) {
    return new RegExp(XpoLtlValidationRegexPatterns.caPostalCode).test(inputValue as string);
  }

  isValidTrailerNumber(inputValue: string) {
    const numberTrailerRegExp = new RegExp(XpoLtlValidationRegexPatterns.numberTrailerPattern).test(inputValue);
    const letterTrailerRegExp = new RegExp(XpoLtlValidationRegexPatterns.letterTrailerPattern).test(inputValue);
    return numberTrailerRegExp || letterTrailerRegExp;
  }

  isValidTractorNumber(inputValue: string) {
    return new RegExp(XpoLtlValidationRegexPatterns.tractorPattern).test(inputValue);
  }

  isValidProNumberCheckDigit(inputValue: string | undefined | null) {
    if (!new RegExp(XpoLtlValidationRegexPatterns.validProCharacterCheck).test(_toString(inputValue))) {
      return false;
    }

    const proNumber = this.conditioningService.conditionProNumber(inputValue as string);
    if (proNumber === '' || proNumber.length < 9 || proNumber.length > 11) {
      return false;
    }
    // divide first 8 chars by 7 multiple remainder by 7
    const checkDigit = Number(proNumber.substring(8));
    // tslint:disable-next-line:radix
    const number = Number.parseInt(proNumber.substring(0, 8));
    const mod = number % 7;
    return mod === checkDigit;
  }

  // Added New function to verify entered Pro is of 9 or 11 digit
  proNumberCheckDigit(inputValue: string | undefined) {
    const proNumber = this.conditioningService.conditionProNumber(inputValue);
    if (proNumber === '' || proNumber.length < 9 || proNumber.length > 11) {
      return false;
    }
    return true;
  }

  isSicValid(value: string | any[] | null) {
    return (
      value != null && value.length === 3 && new RegExp(XpoLtlValidationRegexPatterns.sicValidationPattern).test(value as string)
    );
  }
  isMadCodeValid(value: string | any[] | null): boolean {
    return (
      value != null &&
      value.length === 11 &&
      new RegExp(XpoLtlValidationRegexPatterns.madCodeValidationPattern).test(value as string)
    );
  }
  isBillToMadCodeValid(value: string | any[] | null) {
    return (
      value != null &&
      value.length === 11 &&
      new RegExp(XpoLtlValidationRegexPatterns.billToMadCodeValidationPattern).test(value as string)
    );
  }
  isValidNumeric(value: string | number | null | undefined) {
    return value != null && _trim(value as string).length > 0 && !isNaN(value as number);
  }

  isValidAlphaNumeric(value: string | number | null): boolean {
    return value != null && /^[a-z0-9]+$/i.test(value as string);
  }

  containsLetters(value: string | number | null): boolean {
    return value != null && /[a-z]/i.test(value as string);
  }

  isNmfcValid(value: string | any[] | null) {
    return (
      value != null && value.length >= 4 && new RegExp(XpoLtlValidationRegexPatterns.nmfcValidationPattern).test(value as string)
    );
  }
}
