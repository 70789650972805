import { Pipe, PipeTransform } from '@angular/core';
import { XpoLtlConditioningService } from '../../services/conditioning-service';
import { XpoLtlFormatValidationService } from '../../services/format-validation/format-validation.service';

@Pipe({
  name: 'proFormatter',
})
export class ProFormatterPipe implements PipeTransform {
  constructor(
    private formatService: XpoLtlFormatValidationService,
    private conditioningService: XpoLtlConditioningService
  ) {}

  transform(pro: string, digits: number): string | undefined {
    if (!pro || !digits) {
      return undefined;
    }

    if (this.formatService.isValidProNumber(pro)) {
      return this.conditioningService.conditionProNumber(pro, digits);
    }

    return pro;
  }
}
