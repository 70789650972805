import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmptyValuePipe } from './empty-value/empty-value.pipe';
import { MomentDatePipe } from './moment-date/moment-date.pipe';
import { ProFormatterPipe } from './pro-formatter/pro-formatter.pipe';
import { YesOrNoPipe } from './yes-or-no/yes-or-no.pipe';

const pipes = [
  EmptyValuePipe,
  ProFormatterPipe,
  MomentDatePipe,
  YesOrNoPipe,
];

@NgModule({
  declarations: pipes,
  imports: [CommonModule],
  exports: pipes,
  providers: pipes,
})
export class XpoLtlPipeModule {}
